import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { APIResponse, PaginatedResponse } from '@dmc-ng/data-access';
import { API_BASE_URL } from '@dmc-ng/data-access/injection-token';
import { ScopedUserModel } from '@dmc-ng/data-access/user';
import { SortModel, SortUtils } from '@dmc-ng/utils';
import { Observable } from 'rxjs';

@Injectable()
export class OrganizationUsersService {
  private readonly apiBaseUrl = inject(API_BASE_URL);

  constructor(private readonly httpClient: HttpClient) {}

  getUsers(
    limit?: number,
    offset?: number,
    organizationId?: string,
    sort?: SortModel,
  ): Observable<APIResponse<PaginatedResponse<ScopedUserModel>>> {
    let params = new HttpParams();

    if (organizationId) {
      params = params.append('organizationId', organizationId);
    }
    if (limit) {
      params = params.append('limit', limit);
    }
    if (offset) {
      params = params.append('offset', offset);
    }

    if (sort) {
      params = params.append('sort', SortUtils.createQueryParams(sort));
    }

    return this.httpClient.get<APIResponse<PaginatedResponse<ScopedUserModel>>>(
      `${this.apiBaseUrl}/users/`,
      { params },
    );
  }
}
